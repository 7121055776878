.inventory-page {
  background: $background;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
  }

  .packages-loading-notice {
    position: fixed;
    bottom: 24px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: $secondary;
    padding: 6px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    width: max-content;
    @media screen and (max-width: $breakpoint-sm) {
      bottom: 84px;
    }
  }
}

.mobileBreak {
  @media (max-width: 600px) {
    margin-top: 88px !important;
  }
}
.inventory-products {
  position: relative;

  &-card {
    width: 210px !important;
    height: 210px !important;
    border-radius: 8px;
    overflow: hidden;
    background: $dark;

    &-inner {
      color: #f7f7f7;

      .q-img__content {
        transition: all 0.3s ease-in-out;
        background: linear-gradient(
          rgba(0, 0, 0, 0.32),
          rgba(255, 255, 255, 0.27),
          rgba(0, 0, 0, 0.67)
        );
      }

      &:hover {
        .q-img__content {
          background: linear-gradient(
            rgba(0, 0, 0, 0.47),
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.97)
          );
          box-shadow: inset 3px -2px 25px adjust-color($dark, $alpha: -0.6);
        }
      }
    }

    &-bottom {
      padding: 13px;
    }

    &-title {
      font-size: 18px;
    }

    &-category {
      font-size: 10px;
    }
  }
}

.sync-panel {
  text-align: right;
  margin-top: -34px;
  padding-right: 17px;
  margin-bottom: 20px;

  .last-polled-period {
    margin-bottom: 4px;

    .period {
      font-weight: 600;
    }
  }

  @media (min-width: $breakpoint-sm-min) {
    &.polling {
      margin-top: -75px;
      margin-bottom: 60px;
    }
  }
  @media (max-width: $breakpoint-sm-max) {
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.inventory-items {
  .top-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  .filters-container {
    display: flex;
    gap: 10px;
  }

  .product-line {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f0f0f0;
      cursor: pointer;
      border-radius: 8px;
      padding: 6px 12px;
      margin-top: 10px;
      min-height: 60px;
    }

    &-header-wrapper {
      display: flex;
      cursor: pointer;
      padding: 16px 12px;
      margin-bottom: -10px;
      color: #646464;
      font-weight: 500;

      &-wrapper {
        padding: 15px 12px;
      }

      .sortable-header {
        .q-icon {
          transition: opacity 0.3s ease-in-out;
          margin-left: 5px;
          font-size: 16px;
        }
      }

      .product-line-card {
        &-main {
          padding-left: 10px;
          margin-left: 36px;
          width: 32%;
        }
        &-product-total {
          width: 32%;
        }
        &-qoh {
          width: 32%;
        }
      }
    }
  }

  .product-line-name {
    width: 32%;
    font-weight: 600;
    font-size: 16px;
    color: #333;
  }

  .product-line-products {
    padding-left: 6px;
    width: 32%;
    display: flex;
    align-items: center;
    color: #2a6b0e;
    font-weight: 500;
  }

  .product-line-qoh {
    width: 32%;
    display: flex;
    align-items: center;
    color: $primary;
    font-weight: 500;
  }

  .product-line-dropdown {
    margin-right: 16px;
    width: 4%;
    background: #f7f7f7;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  &-header {
    display: flex;
    align-items: center;
    width: 100%;
    color: #646464;
    font-weight: 500;
    position: relative;

    &-wrapper {
      padding: 4px 12px;
    }

    .inventory-items-card {
      &-main {
        padding-left: 84px;
        &.for-retailer {
          padding-left: 0;
        }
      }
    }

    &-select {
      background: #fff;
      display: inline-flex;
      border-radius: 6px;
      overflow: hidden;
      min-width: 110px;

      .q-field {
        &__native {
          color: $dark;
          font-weight: 500;
        }
        &__label {
          color: #565656;
        }
      }

      .q-select {
        &__dropdown-icon {
          color: $dark;
          font-size: 14px;
        }
      }
    }

    .inventory-items-card {
      &-main {
        @media (max-width: $breakpoint-md-max) {
          margin-bottom: 0;
        }
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }
      &-category-type {
        @media (max-width: $breakpoint-md-max) {
          width: 10%;
        }
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }
      &-strain-type {
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }
      &-packages {
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
        @media (max-width: 1260px) {
          display: none;
        }
      }
      &-listings {
        @media (max-width: $breakpoint-md-max) {
          width: 12%;
        }
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }
      &-vendor {
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }
      &-brand {
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }
      &-unit {
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }
      &-price {
        @media (max-width: $breakpoint-sm-max) {
          display: none;
        }
      }
      &-filters {
        @media (max-width: $breakpoint-sm-max) {
          width: 100%;
        }

        > * {
          margin-right: -12px;
          margin-left: 22px;
        }
      }
    }
  }

  &-scroll {
    max-height: 100vh;
  }

  &-card {
    color: $dark;
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: $breakpoint-sm-max) {
      flex-wrap: wrap;
    }

    &.with-packages {
      cursor: pointer;
    }

    &.actions-in-row {
      padding-bottom: 25px;
    }

    &-info-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 25%;
    }

    &-packages {
      flex: 1;
    }

    &-toggle {
      flex-shrink: 0;
    }

    &-wrapper {
      border-radius: 8px;
      padding: 10px 12px;
      margin-bottom: 10px;
      min-height: 80px;
      flex-wrap: wrap;

      &:not(.q-skeleton) {
        background: #fff;
      }

      &.info-row {
        background: transparent;
        min-height: unset;
      }
    }

    &-main {
      width: 25%;
      display: flex;
      align-items: center;

      @media (max-width: $breakpoint-md-max) {
        width: 40%;
      }

      .inventory-items-card-image {
        background: $secondary;
        border-radius: 8px;
        margin-right: 16px;
        min-width: 60px;
        min-height: 60px;

        &.no-image {
          color: $primary;
          font-weight: 500;
          padding: 10px;
          width: 60px;
          height: 60px;
          text-align: center;
          position: relative;

          .no-image-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      &.for-retailer {
        width: 15%;
        flex-direction: column;
        align-items: start;

        .inventory-items-card-image {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }

    &-category-type {
      width: 10%;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-facility {
      width: 10%;
      margin-right: 6px;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-license {
      width: 10%;
      margin-right: 6px;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-vendor {
      width: 15%;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-brand {
      width: 10%;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-unit {
      width: 10%;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 66.6%;
      }
    }

    &-strain-type {
      width: 10%;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 66.6%;
      }
    }

    &-listings {
      width: 10%;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }
    }

    &-price {
      width: 10%;
      text-align: center;

      @media (max-width: $breakpoint-sm-max) {
        width: 20%;
      }

      @media (max-width: $breakpoint-xs-max) {
        width: 66.6%;
      }
    }

    &-actions {
      width: 20%;
      text-align: right;

      @media (max-width: $breakpoint-xs-max) {
        width: 33.3%;
      }

      &-buttons {
        display: inline-flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: flex-end;

        &.wrap-in-rows {
          flex-direction: column;
          align-items: flex-end;
          gap: 5px;
        }
      }
    }

    &-filters {
      width: 40%;
      text-align: right;
      @media (min-width: $breakpoint-md-min) {
        width: auto;
        position: absolute;
        right: 0;
        bottom: 40px;
      }
    }

    &-packages-list {
      margin-top: 20px;
      width: 100%;

      &-table {
        thead {
          tr {
            height: auto;
            @media (max-width: $breakpoint-sm-max) {
              display: none;
            }
          }
          th {
            border: none;
            color: #646464;
            font-size: 12px;
            padding-top: 0;
            padding-bottom: 0;
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        }
        tbody {
          tr {
            @media (max-width: $breakpoint-xs-max) {
              display: flex;
              flex-wrap: wrap;
              border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            }
          }
          td {
            color: #092629;
            padding: 15px;
            font-size: 14px;
            @media (max-width: $breakpoint-xs-max) {
              border: none;
              height: auto;
              padding: 15px;
            }
            &:first-child {
              user-select: text !important;
              padding-left: 0;
              @media (max-width: $breakpoint-xs-max) {
                width: 100%;
                font-weight: 500;
                margin-bottom: -15px;
              }
            }
            &:nth-child(2),
            &:nth-child(3) {
              @media (max-width: $breakpoint-xs-max) {
                padding-left: 0;
                padding-top: 20px;
              }
            }
            &:last-child {
              padding-right: 0;
              @media (max-width: $breakpoint-xs-max) {
                flex-grow: 1;
              }
            }
            &:before {
              content: none !important;
            }
          }
        }
      }
    }

    &-title {
      font-size: 16px;
      font-weight: 600;
      margin-right: 2px;
    }

    &-packages {
      width: 10%;
    }

    &-do-inventory-listings {
      flex: 0 0 10%;
      margin: 0 10px;
    }

    &-actions-buttons {
      flex: 0 0 12%;
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      &.retailer-action-buttons {
        width: auto;
      }
    }

    &-clickable {
      color: $primary;
      font-weight: 500;

      .accordion-icon {
        color: #646464;
        background: #f7f7f7;
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }

      .q-btn {
        &__content {
          gap: 5px;
          flex-wrap: nowrap;
        }
      }
    }

    &-button {
      border-radius: 6px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .brand-header-wrapper {
    display: flex;
    cursor: pointer;
    padding: 16px 12px;
    margin-bottom: -10px;
    color: #646464;
    font-weight: 500;

    .brand-card-main {
      width: 25%;
      padding-left: 50px;
    }

    @media (max-width: 1260px) {
      .brand-card-main {
        width: 35%;
      }
      .brand-card-product-total,
      .brand-card-qoh {
        width: 20%;
      }
    }

    @media (max-width: $breakpoint-sm-max) {
      flex-wrap: wrap;

      .brand-card-main {
        width: 100%;
        padding-left: 16px;
        margin-bottom: 8px;
      }

      .brand-card-product-total,
      .brand-card-qoh {
        width: 50%;
        padding-left: 16px;
      }
    }
  }

  .brand-group {
    .brand-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f0f0f0;
      cursor: pointer;
      border-radius: 8px;
      padding: 6px 12px;
      margin-top: 10px;
      min-height: 60px;

      .brand-info {
        display: flex;
        align-items: center;
        margin-left: 6px;

        .brand-logo {
          width: 40px;
          height: 40px;
          margin-right: 14px;
          border-radius: 4px;
        }

        .brand-name {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .brand-metrics {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-right: 16px;

        span {
          font-weight: 500;
          color: #2a6b0e;
        }
      }

      .brand-dropdown-icon {
        font-size: 24px;
        color: #646464;
      }
    }
  }
}
.inventory-items .brand-group .brand-content {
  background: #fff;
  padding: 0 16px 16px;

  .product-line-group {
    .inventory-items-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 12px;
      color: #646464;
      font-size: 12px;

      .inventory-items-card-main {
        width: 24%;
        padding-left: 75px;
      }

      .inventory-items-card-license,
      .inventory-items-card-strain-type {
        width: 10%;
        padding-left: 10px;
      }

      .inventory-items-card-category-type {
        width: 10%;
        padding-left: 10px;
      }

      .inventory-items-card-listings,
      .inventory-items-card-packages {
        width: 10%;
        padding-left: 10px;
      }

      .inventory-items-card-toggle {
        width: 10%;
        padding-left: 10px;
      }

      @media (max-width: 1260px) {
        .inventory-items-card-main {
          width: 35%;
        }

        .inventory-items-card-license,
        .inventory-items-card-category-type,
        .inventory-items-card-strain-type {
          width: 20%;
        }

        .inventory-items-card-listings,
        .inventory-items-card-packages {
          width: 12.5%;
        }
      }

      @media (max-width: $breakpoint-sm-max) {
        display: none;
      }
    }
  }
}

.q-slide-transition {
  &-enter-active,
  &-leave-active {
    transition: all 0.3s ease;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.reorder-items-table {
  .q-table__bottom {
    background: $grey-2;
  }
}

@media (max-width: $breakpoint-md-max) {
  .inventory-items-card {
    flex-wrap: wrap;

    &-info-wrapper {
      justify-content: flex-start;
      order: 1;
    }

    &-packages {
      margin-right: 20px;
    }

    &-actions-buttons {
      order: 2;
      justify-content: flex-end;
    }
  }
  .inventory-items-card-main {
    width: 25%;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 1260px) {
  .inventory-items-header {
    .inventory-items-card-category-type {
      width: 12%;
    }

    .inventory-items-card-license {
      width: 15%;
    }
  }
  .inventory-items-card {
    flex-wrap: wrap;

    &-facility {
      width: 15%;
    }

    &-category-type {
      width: 12%;
    }
    &-strain-type {
      width: 12%;
    }

    &-info-wrapper {
      width: 40%;
      justify-content: flex-start;
      order: 1;
      margin-top: 10px;
    }

    &-packages {
      margin-right: 20px;
    }

    &-actions-buttons {
      order: 2;
      justify-content: flex-end;
    }
  }
  .inventory-items-card-main {
    width: 35%;
    display: flex;
    align-items: center;
  }
}

@media (max-width: $breakpoint-sm-max) {
  .inventory-items-card {
    &-info-wrapper {
      width: 100%;
      flex-wrap: wrap;
    }

    &-packages,
    &-toggle {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}

.inventory-items-card-checkbox {
  padding-top: 20px;
}
.inventory-items-for-order {
  .inventory-items-header {
    padding-right: 20px;
    padding-left: 40px;
  }
  .inventory-items-card-main {
    width: 40% !important;
  }
  .inventory-items-card-category-type {
    width: 15% !important;
  }
  .inventory-items-card-strain-type {
    width: 15% !important;
  }
  .inventory-items-card-qty {
    width: 15% !important;
  }
  .inventory-items-card-price {
    width: 15% !important;
    text-align: left;
  }
}

.product-line-section {
  margin-top: 16px;

  .product-line-card {
    border-radius: 8px;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    .q-expansion-item__container {
      background: white;
    }

    .q-item {
      padding: 16px;
      min-height: 72px;

      @media (max-width: $breakpoint-xs) {
        flex-wrap: wrap;
        padding: 12px;

        .q-item__section--side {
          padding-top: 8px;
          width: 100%;
          align-items: flex-start;
        }

        .text-h6 {
          font-size: 1.1rem;
        }
      }
    }

    .q-card__section {
      padding: 16px;

      @media (max-width: $breakpoint-xs) {
        padding: 8px;
      }
    }
  }

  .inventory-items-header {
    $main-width: var(--main-width, 25%);
    $column-width: var(--column-width, 10%);

    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    color: #646464;
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 0;

    .inventory-items-card-main {
      .show-license & {
        width: 25%;
        @media (max-width: $breakpoint-md-max) {
          width: 40%;
        }
      }

      .hide-license & {
        width: 35%;
        @media (max-width: $breakpoint-md-max) {
          width: 45%;
        }
      }
    }

    .inventory-items-card-category-type,
    .inventory-items-card-strain-type,
    .inventory-items-card-listings {
      .show-license & {
        width: 10%;
        @media (max-width: $breakpoint-md-max) {
          width: 12%;
        }
      }

      .hide-license & {
        width: 12%;
        @media (max-width: $breakpoint-md-max) {
          width: 15%;
        }
      }
    }

    .inventory-items-card-packages {
      .show-license & {
        width: 10%;
      }
      .hide-license & {
        width: 12%;
      }
    }

    @media (max-width: 768px) {
      .inventory-items-card-category-type,
      .inventory-items-card-strain-type,
      .inventory-items-card-listings,
      .inventory-items-card-packages,
      .inventory-items-card-toggle {
        width: 45%;
        margin-bottom: 8px;
      }
    }

    @media (max-width: 480px) {
      .inventory-items-card-category-type,
      .inventory-items-card-strain-type,
      .inventory-items-card-listings,
      .inventory-items-card-packages,
      .inventory-items-card-toggle {
        width: 100%;
      }
    }
  }

  &.product-drop-hover {
    border: 2px dashed var(--q-primary);
    background: rgba(42, 107, 14, 0.05);
    box-shadow: 0 0 15px rgba(42, 107, 14, 0.2);
  }
}
