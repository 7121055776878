.orders-page {
  background: $background;

  .orders {
    &-filters {
      margin: 16px 0;
    }

    &-header {
      display: flex;
      align-items: center;
      width: 100%;
      color: #646464;
      font-weight: 500;
      position: relative;
      justify-content: space-between;
      padding: 15px 5px;

      &-wrapper {
        padding: 15px 5px;
      }

      &-left {
        padding-left: 0.285em;
      }

      .sortable-header {
        min-height: unset;
        height: 20px;
        padding: 0;

        .q-btn__content {
          display: flex;
          align-items: center;
          min-width: 0;

          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .q-icon {
            flex-shrink: 0;
            font-size: 16px;
          }
        }
      }

      @media (max-width: 821px) {
        display: none;
      }

      @media (min-width: 1301px) {
        // Changed from 1091px to 1301px
        .orders-card-left {
          width: 5%;
        }
        .orders-card-source {
          width: 8%;
        }
        .orders-card-type {
          width: 8%;
        }
        .orders-card-number {
          width: 9%;
        }
        .orders-card-customer {
          width: 15%;
        }
        .orders-card-date {
          width: 10%;
        }
        .orders-card-shipped-date {
          width: 15%;
        }
        .orders-card-total {
          width: 10%;
        }
        .orders-card-status {
          width: 10%;
        }

        // Offers
        .orders-card-offers-title {
          width: 20%;
        }
        .orders-card-offers-type {
          width: 12%;
        }
        .orders-card-offers-date {
          width: 15%;
        }
        .orders-card-offers-qty {
          width: 12%;
        }
        .orders-card-offers-total {
          width: 15%;
        }
        .orders-card-offers-status {
          width: 16%;
        }
        .orders-card-offers-actions {
          width: 10%;
        }
      }

      @media (min-width: 1091px) and (max-width: 1300px) {
        .orders-card-left {
          width: 4%;
        }
        .orders-card-source {
          width: 7%;
        }
        .orders-card-type {
          width: 7%;
        }
        .orders-card-number {
          width: 8%;
        }
        .orders-card-customer {
          width: 13%;
        }
        .orders-card-date {
          width: 13%;
        }
        .orders-card-shipped-date {
          width: 13%;
        }
        .orders-card-total {
          width: 12%;
        }
        .orders-card-status {
          width: 15%;
        }
        .orders-card-actions {
          width: 10%;
        }

        // Offers
        .orders-card-offers-title {
          width: 20%;
        }
        .orders-card-offers-type {
          width: 12%;
        }
        .orders-card-offers-date {
          width: 15%;
        }
        .orders-card-offers-qty {
          width: 12%;
        }
        .orders-card-offers-total {
          width: 15%;
        }
        .orders-card-offers-status {
          width: 16%;
        }
        .orders-card-offers-actions {
          width: 10%;
        }
      }

      // Medium Screens - Fixed Alignment
      @media (min-width: 822px) and (max-width: 1090px) {
        .orders-card-left {
          width: 5%;
        }
        .orders-card-source {
          width: 10%;
        }
        .orders-card-type {
          display: none;
        }
        .orders-card-number {
          width: 10%;
        }
        .orders-card-customer {
          padding-left: 1.4rem;
          width: 17%;
        }
        .orders-card-date {
          width: 12%;
        }
        .orders-card-shipped-date {
          width: 16%;
        }
        .orders-card-total {
          width: 10%;
        }
        .orders-card-status {
          width: 10%;
        }

        // Offers
        .orders-card-offers-title {
          width: 20%;
        }
        .orders-card-offers-type {
          width: 12%;
        }
        .orders-card-offers-date {
          width: 15%;
        }
        .orders-card-offers-qty {
          width: 12%;
        }
        .orders-card-offers-total {
          width: 15%;
        }
        .orders-card-offers-status {
          width: 16%;
        }
        .orders-card-offers-actions {
          width: 10%;
        }
      }
    }

    &-scroll {
      max-height: 100vh;

      &-item {
        padding: 0;
      }
    }

    &-card {
      color: $dark;
      font-size: 14px;
      font-weight: 500;
      width: 100%;

      @media (min-width: 821px) {
        display: flex;
        align-items: center;
      }

      &-wrapper {
        cursor: pointer;
        border-radius: 8px;
        padding: 10px 5px;
        margin-bottom: 10px;
        min-height: 60px;
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &:not(.q-skeleton) {
          transition: box-shadow 0.3s ease-in-out;

          &:hover {
            box-shadow: 0 0 1.25rem #0000001a;
          }
        }
      }

      &-left {
        width: 3%;

        @media (min-width: 1301px) {
          width: 5%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 5%;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 5%;
        }
      }

      &-source {
        width: 5%;

        @media (min-width: 1301px) {
          width: 8%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 7%;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 10%;
        }
      }

      &-type {
        width: 5%;

        @media (min-width: 1301px) {
          width: 8%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 7%;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          display: none;
        }
      }

      &-number {
        width: 8%;

        @media (min-width: 1301px) {
          width: 9%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 8%;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 8%;
          margin-left: 9px;
        }
      }

      &-customer {
        width: 15%;

        @media (min-width: 1301px) {
          width: 15%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 13%;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 15%;
        }
      }

      &-date {
        width: 15%;

        @media (min-width: 1301px) {
          width: 10%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 10%;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 12%;
        }
      }

      &-shipped-date {
        width: 15%;

        .q-mb-xs {
          margin-bottom: 4px !important;
        }

        .full-width-btn {
          min-height: 28px;
          padding: 2px 8px !important;
          font-size: 12px;
          white-space: nowrap;
          width: calc(100% - 4px) !important;
          margin: 2px;

          .q-btn__content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block !important;
          }
        }

        @media (min-width: 1301px) {
          width: 15%;
          .full-width-btn {
            min-height: 32px;
            font-size: 13px;
          }
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 13%;
          min-width: 140px;

          .full-width-btn {
            padding: 1px 6px !important;
          }
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 16%;
        }
      }

      &-total {
        width: 10%;

        @media (min-width: 1301px) {
          width: 10%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 8%;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 14%;

          .full-width-btn {
            padding: 1px 4px !important;
          }
        }

        @media (max-width: 821px) {
          .full-width-btn {
            width: auto !important;
            margin-left: 100px;
          }
        }
      }

      &-status {
        width: 10%;

        @media (min-width: 1301px) {
          width: 10%;
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 12%;
          min-width: 110px;
          padding-right: 4px;
          justify-content: flex-end;
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 12%;
          display: flex;
          align-items: center;

          .q-badge {
            width: 100%;
            height: auto;
            white-space: normal;
            text-align: center;
          }
        }
      }

      &-actions {
        width: 15%;
        display: flex;
        gap: 6px;
        justify-content: flex-end;
        margin-left: auto;

        @media (min-width: 1301px) {
          width: 12%;
          gap: 2px;

          .q-btn {
            .q-icon {
              font-size: 18px;
            }
          }
        }
        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 12%;
          justify-content: flex-end;
          flex-wrap: wrap;
          gap: 2px;

          .q-btn {
            padding: 2px;

            .q-icon {
              font-size: 18px;
            }
          }
        }
        @media (min-width: 1120px) and (max-width: 1091px) {
          width: 12%;
          justify-content: flex-end;
          flex-wrap: wrap;
          gap: 2px;

          .q-btn {
            padding: 2px;

            .q-icon {
              font-size: 18px;
            }
          }
        }
        @media (min-width: 822px) and (max-width: 1090px) {
          width: 10%;
          flex-wrap: wrap;
          gap: 2px;

          .q-btn {
            padding: 4px !important;
            min-height: 24px;

            .q-icon {
              font-size: 16px;
            }
          }
        }

        @media (max-width: 821px) {
          padding-top: 8px;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-left: 6.4rem;
        }
      }

      &-offers {
        &-title {
          width: 20%;
        }
        &-type {
          width: 15%;
        }
        &-date {
          width: 15%;
        }
        &-qty {
          width: 10%;
        }
        &-total {
          width: 15%;
        }
        &-status {
          width: 15%;
        }
        &-actions {
          width: 10%;
          display: flex;
          gap: 10px;
          justify-content: flex-end;
          margin-left: auto;

          @media (min-width: 1301px) {
            width: 10%;
          }
          @media (min-width: 1091px) and (max-width: 1300px) {
            width: 10%;
          }
          @media (min-width: 822px) and (max-width: 1090px) {
            width: 10%;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
          }
        }
      }

      &-checkbox {
        margin-right: 5px;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0;

        .q-checkbox__inner--falsy {
          .q-checkbox__bg {
            background: #fff;
          }
        }

        @media (min-width: 1091px) and (max-width: 1300px) {
          width: 40px;
          padding-top: 0;
          display: flex;
          align-items: center;
        }
      }

      > div {
        padding-left: 0.285em;
      }

      @media (max-width: 1300px) {
        .q-badge {
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      @media (max-width: 821px) {
        display: grid;
        grid-template-columns: 1fr;
        gap: 8px;

        [class*="orders-card-"] {
          width: 100%;
          padding: 4px 0;
          display: flex;
          align-items: center;

          &.orders-card-type {
            display: flex;
          }

          &:not(.orders-card-left):not(.orders-card-actions)::before {
            content: attr(data-label);
            font-weight: 600;
            min-width: 100px;
            margin-right: 8px;
          }
        }

        [class*="orders-card-offers-"] {
          width: 100%;
          padding: 4px 0;
          display: flex;
          align-items: center;

          &:not(.orders-card-offers-actions)::before {
            content: attr(data-label);
            font-weight: 600;
            min-width: 100px;
            margin-right: 8px;
          }
        }

        &-offers-actions,
        &-actions {
          padding-top: 8px;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-left: 6.4rem;
        }
      }

      [class*="orders-card-"] {
        @media (min-width: 822px) {
          display: flex;
          align-items: center;
          // margin-right: 12px;
        }
      }

      .full-width-btn {
        width: 100%;
        max-width: 100%;
        margin-bottom: 5px;

        @media (min-width: 822px) {
          width: auto;
          margin-bottom: 0;
        }
      }
    }

    &-pagination {
      gap: 5px;

      .row {
        gap: 5px;
      }

      .q-btn {
        background: #fff;
        padding: 5px !important;
      }
    }
  }

  .single-order {
    &-card {
      border-radius: 8px;
      padding: 10px 12px;
      background: #fff;
      height: 100%;

      &-label {
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
}

.order-badge {
  padding: 10px 15px;
}

.order-shipping-row {
  &.move-top {
    margin-top: -20px;
  }

  .q-btn-dropdown {
    .q-btn__content {
      justify-content: space-between;
    }
  }
}

.order-items-table {
  &-row {
    background: #fff;
    border-radius: 8px;
    padding: 6px 12px;
    margin-bottom: 10px;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;

    &.accepted {
      background: #ddecdf;
    }
    &.declined {
      background: #ffcdd2;
    }

    &-left {
      width: 10%;
    }

    &-product {
      width: 20%;

      &-image {
        margin-right: 8px;
      }

      &-name {
        font-weight: 600;
      }
    }

    &-brand,
    &-qty,
    &-qoh,
    &-unit-price,
    &-total-price {
      width: 10%;
      font-weight: 500;
    }

    &-notes {
      width: 20%;

      &.small {
        width: 10%;
      }
    }

    &-actions {
      width: 10%;
      text-align: right;

      @media (min-width: 822px) {
        width: 10%;
      }
    }

    &-divider {
      width: 100%;
      height: 2px;
      background: $grey-3;
      margin-top: 10px;
    }

    &-packages {
      padding-top: 16px;
      width: 100%;
      display: flex;

      .packages-main {
        width: 100%;
        max-width: 800px;

        table {
          width: 100%;

          th {
            text-align: left;
          }
        }
      }

      .new-package {
        width: 50%;

        &-form {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 10px;
          grid-auto-rows: minmax(100px, auto);
        }
      }
    }
  }

  &-header {
    padding: 5px 12px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    position: relative;

    &.left-padding {
      padding-left: 57px;
    }

    .bulk-actions {
      position: absolute;
      left: 0;

      .q-checkbox__label {
        margin-left: 20px;
      }
    }
  }

  &-accepted {
    background: #fff;
    border-radius: 8px;
  }

  &-checkbox {
    width: 45px;
    padding-top: 20px;
  }
}

.my-orders {
  &-header {
    &-select {
      min-width: 100px;
    }

    &-dropdown {
      min-width: 150px;
      font-size: 14px;
      font-weight: normal;
      text-transform: capitalize;

      &.per-page {
        min-width: 100px;
      }
    }
  }
}
